<template>
  <div class="map">
    <baidu-map class="bm-view" @ready="init" :zoom="19" :center="{ lng: 118.731134, lat: 32.011681 }"></baidu-map>
  </div>
</template>
<!-- <script src="https://unpkg.com/vue-baidu-map"></script> -->
<script>
const CUSTOM_MAP_CONFIG = require("../../../assets/json/custom_map_config.json"); //地图样式
export default {
  name: "Home",
  components: {},
  data() {
    return {
      center: "118.731134,32.011681",
    };
  },
  methods: {
    init({ BMap, map }) {
      this.map = map;
      var point = new BMap.Point(118.731134, 32.011681); //设置城市经纬度坐标
      map.centerAndZoom(point, 15); // 初始化地图,设置中心点坐标和地图级别
      map.setCurrentCity("南京"); // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true); //鼠标滚动缩放
      // map.setHeading(60) //设置地图旋转角度
      // map.setTilt(73)   //设置地图倾斜角度
      map.setMapStyle({ styleJson: CUSTOM_MAP_CONFIG }); //导入主题样式
      /**
       * data_info
       * 可以多个 定位
       *
       *
       *
       * 经纬度坐标  名称，网址
       * */
      var data_info = [[118.731134, 32.011681, "地址：江苏省南京市建邺区奥体中心体育馆一楼G105,G106 <h4><a target='_blank' href='/' style='color:blue;'>www.jsxygkj.com</a></h4>"]];
      var opts = {
        min_width: 250, // 信息窗口zuixiao宽度
        min_height: 80, // 信息窗口高度
        title: "江苏秀圆果信息科技有限公司", // 信息窗口标题
        enableMessage: true, //设置允许信息窗发送短息
      };
      for (var i = 0; i < data_info.length; i++) {
        var marker = new BMap.Marker(new BMap.Point(data_info[i][0], data_info[i][1])); // 创建标注
        var content = data_info[i][2];
        map.addOverlay(marker); // 将标注添加到地图中
        addClickHandler(content, marker);
      }
      function addClickHandler(content, marker) {
        marker.addEventListener("click", function (e) {
          openInfo(content, e);
        });
      }
      function openInfo(content, e) {
        var p = e.target;
        var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
        var infoWindow = new BMap.InfoWindow(content, opts); // 创建信息窗口对象
        map.openInfoWindow(infoWindow, point); //开启信息窗口
      }

      // //向地图中添加缩放控件
      // var ctrl_nav = new BMap.NavigationControl({anchor:CUSTOM_MAP_CONFIG,type:CUSTOM_MAP_CONFIG});
      // map.addControl(ctrl_nav);
      // //向地图中添加缩略图控件
      // var ctrl_ove = new BMap.OverviewMapControl({anchor:CUSTOM_MAP_CONFIG,isOpen:1});
      // map.addControl(ctrl_ove);
      // //向地图中添加比例尺控件
      // var ctrl_sca = new BMap.ScaleControl({anchor:CUSTOM_MAP_CONFIG});
      // map.addControl(ctrl_sca);
    },
  },
};
</script>
<style>
.map {
  width: 16.09rem;
  height: 4.93rem;
  margin: auto;
}

.bm-view {
  width: 100%;
  height: 100%;
}
</style>
